/**
 *  Default icons used based on type of file.
 */
export default Object.freeze({
  mimeTypes: [
    {
      type: 'default',
      icon: 'vd-icon-file-unknown',
    },
    {
      type: 'audio',
      icon: 'vd-icon-file-audio',
    },
    {
      type: 'text',
      icon: 'vd-icon-file-doc',
    },
    {
      type: 'msword',
      icon: 'vd-icon-file-doc',
    },
    {
      type: 'ms-word',
      icon: 'vd-icon-file-doc',
    },
    {
      type: 'wordprocessingml',
      icon: 'vd-icon-file-doc',
    },
    {
      type: 'ms-powerpoint',
      icon: 'vd-icon-file-doc',
    },
    {
      type: 'presentationml',
      icon: 'vd-icon-file-doc',
    },
    {
      type: 'ms-excel',
      icon: 'vd-icon-file-excel',
    },
    {
      type: 'spreadsheetml',
      icon: 'vd-icon-file-excel',
    },
    {
      type: 'image',
      icon: 'vd-icon-file-image',
    },
    {
      type: 'pdf',
      icon: 'vd-icon-file-pdf',
    },
    {
      type: 'video',
      icon: 'vd-icon-file-video',
    },
    {
      type: 'zip',
      icon: 'vd-icon-file-zip',
    },
  ],
})
