<template>
  <div>
    <default-loader v-if="projectBrandHubLoading"></default-loader>
    <div v-else>
      <div
        v-if="largeFileWarning"
        class="vd-margin-bottom-10 vd-padding-left-medium vd-padding-top-small vd-padding-bottom-small vd-display-block vd-border-radius-6 vd-background-light-yellow vd-red"
      >
        Please don't close this window, some files are quite large and may take
        a little longer to process.
      </div>
      <div
        :class="[
          'vd-border-lighter-grey vd-border-radius-6 vd-padding-20 vd-grey vd-text-align-center vd-margin-bottom-20',
          brandHub.data.length <= 0 || isMobileScreen
            ? ''
            : 'vms-project-files__content--wrapper-fixed',
        ]"
      >
        <div
          class="vms-uploader__wrapper vd-border-lighter-grey vd-border-radius-6 vd-padding-top-20 vd-padding-bottom-20"
        >
          <file-upload-component
            :api-url="apiUrl"
            @handle:process-file="handleProcessFile"
            @handle:add-file="handleAddFile"
            @handle:update-files="handleUpdateFiles"
          >
          </file-upload-component>
        </div>
      </div>
      <div
        v-if="brandHub.data.length <= 0"
        class="vms-project-files__table--empty vd-text-align-center vd-margin-bottom-extra-large"
      >
        <img src="/images/empty-states/empty-folder.svg" />
        <div class="vd-black">
          There are no items here! Click upload above to attach files to this
          Company.
        </div>
      </div>

      <uploaded-files-list-mobile
        v-else-if="isMobileScreen"
        :files-data="brandHub.data"
      >
        <template v-slot:uploaded-files-options="slotProps">
          <div
            v-if="isMobileScreen"
            class="vms-project-files__list--item__icon vms-project-files__list--item__icon--medium vd-margin-left-small vd-icon-bin"
            @click="handleDelete(slotProps.file)"
          ></div>
        </template>
      </uploaded-files-list-mobile>

      <uploaded-files-list v-else :files-data="brandHub.data">
        <template v-slot:uploaded-files-options="slotProps">
          <brand-hub-dropdown :file="slotProps.file"></brand-hub-dropdown>
        </template>
      </uploaded-files-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, isNull } from 'lodash'
import UploadedFilesList from '@views/project/uploaded-files/components/uploaded-files-list'
import UploadedFilesListMobile from '@views/project/uploaded-files/components/uploaded-files-list-mobile'
import BrandHubDropdown from '@views/project/uploaded-files/brand-hub/brand-hub-dropdown'
import DefaultLoader from '@components/loaders/default-loader'
import FileUploadComponent from '@components/file-upload/file-upload'
import AppConfig from '@configs/app-config'
import SweetAlert from '@plugins/sweet-alert'

export default {
  components: {
    UploadedFilesList,
    DefaultLoader,
    BrandHubDropdown,
    FileUploadComponent,
    UploadedFilesListMobile,
  },

  data() {
    return {
      incompleteUploadWarning: false,
      largeFileWarning: false,
      maxFileSizeWarning: 100000000,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      brandHub: 'project/attachment/brandHub',
      projectBrandHubLoading: 'project/attachment/projectBrandHubLoading',
      authToken: 'auth/accessToken',
      isMobileScreen: 'common/isMobileScreen',
    }),

    apiUrl() {
      return `${AppConfig.vmsApiUrl}/api/v2/internal/projects/${this.project.id}/brand-hub/attachments`
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.incompleteUploadWarning) {
      SweetAlert.fire({
        title: 'Confirm Navigation',
        html: `<br> Your files are still uploading. <br> Leaving this page will cancel all pending file uploads. <br><br> Are you sure you want to leave this page?`,
        showCancelButton: true,
        confirmButtonText: 'Leave this page',
        confirmButtonColor: '#ff3333',
      }).then((result) => {
        if (result.isConfirmed) {
          next()
        }
      })
    } else {
      next()
    }
  },

  methods: {
    ...mapActions({
      getBrandHubList: 'project/attachment/getBrandHubList',
      deleteBrandHubAttachment: 'project/attachment/deleteBrandHubAttachment',
    }),

    handleProcessFile() {
      this.getBrandHubList({
        projectId: this.project.id,
        page: 1,
        include: ['created_by'],
      })

      this.largeFileWarning = false
      this.incompleteUploadWarning = false
    },

    handleAddFile(error, file) {
      if (isNull(error)) {
        this.incompleteUploadWarning = true

        // If file is bigger than 100MB we will show the warning.
        if (file.file.size > this.maxFileSizeWarning) {
          this.largeFileWarning = true
        }
      }
    },

    handleUpdateFiles(files) {
      if (isEmpty(files)) {
        this.largeFileWarning = false
        this.incompleteUploadWarning = false
      }
    },

    handleDelete(file) {
      SweetAlert.fire({
        title: 'Warning!',
        html: `<br>Are you sure you want to delete this file? <br><br> <strong>${file.name}</strong>`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteBrandHubAttachment({
            project_id: this.project.id,
            attachment_id: file.id,
          })
        }
      })
    },
  },
}
</script>
