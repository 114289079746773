<template>
  <div class="vms-project-files__list">
    <div
      v-for="(file, index) in filesData"
      :key="index"
      class="vms-project-files__list--item vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium vd-margin-top-medium"
    >
      <div class="vms-project-files__list--item__header row no-gutters">
        <div class="vd-margin-right-small">
          <span
            :class="[
              'vms-project-files__list--item__icon vms-project-files__list--item__icon--small',
              getIconClassForMimeType(file.mime_type),
            ]"
          >
          </span>
        </div>

        <div
          class="vd-full-width vd-display-inline-block vd-black vd-margin-right-large"
        >
          <div
            class="vms-project-files__list--item__name vd-margin-bottom-extra-small"
          >
            {{ file.name }}
          </div>
          <div class="vd-dark-grey">
            {{ file.created_at | unixToFormat('DD MMM YYYY') }}
          </div>
        </div>

        <a :href="file.url" target="_blank" rel="noopener" class="vd-a">
          <span class="vms-project-files__list--item__icon vd-icon-eye"></span>
        </a>

        <slot name="uploaded-files-options" :file="file"></slot>
      </div>

      <hr class="vd-border-grey" />

      <div class="vms-project-files__list--item__footer row no-gutters">
        <div>
          <div
            v-if="file.created_by.photo_url === null"
            class="vms-project-files__icon--user vd-display-inline-block vd-margin-right-medium vd-icon-profile"
          ></div>
          <div
            v-else
            class="vms-project-files__icon--profile vd-display-inline-block vd-margin-right-medium"
          >
            <img class="vd-border-radius-50" :src="file.created_by.photo_url" />
          </div>
          <div class="vd-display-inline-block">
            {{ file.created_by.full_name }}
          </div>
        </div>

        <div class="vd-dark-grey">
          {{ file.size | formatBytes }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import IconsMixin from '@mixins/icons-mixin'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },

  mixins: [FiltersMixin, IconsMixin],

  props: {
    filesData: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },
}
</script>
