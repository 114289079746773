import { includes, lowerCase, find, isNil } from 'lodash'
import MimeTypeIconsConfig from '@configs/mime-type-icons'

/**
 * @namespace mixins/icons-mixin
 */

export default {
  methods: {
    getVideoProductTypeIconClass(videoProductType) {
      let icon = 'vd-icon-product-edit'

      if (includes(lowerCase(videoProductType), 'animation')) {
        icon = 'vd-icon-product-animation'
      } else if (includes(lowerCase(videoProductType), 'social')) {
        icon = 'vd-icon-product-social'
      } else if (includes(lowerCase(videoProductType), 'upload')) {
        icon = 'vd-icon-product-upload'
      }

      return icon
    },

    getIconClassForMimeType(mimeType) {
      let iconClass = find(MimeTypeIconsConfig.mimeTypes, (configVal) => {
        return includes(mimeType, configVal.type)
      })

      // If the mime type icon is undefined, we will return the default icon data
      if (isNil(iconClass)) {
        iconClass = find(MimeTypeIconsConfig.mimeTypes, {
          type: 'default',
        })
      }

      return iconClass.icon
    },
  },
}
