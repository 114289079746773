import momentTz from 'moment-timezone'
import { truncate, isNil } from 'lodash'

/**
 * Helper filters for dates, strings and more.
 * @module mixins/filters
 */

export default {
  filters: {
    /**
     * Try to pluralize an English word based on the counter.
     * @param {Number} count - Number used to determine if plural or singular.
     * @param {String} noun - The word in singular form that will be pluralised.
     * @param {String} suffix - Optional if you wish to change suffix.
     * @returns {String}
     * @example
     * // returns cars
     * pluralize(2, 'car')
     * // returns children
     * pluralize(2, 'child', 'ren')
     */
    pluralizeEnglish: (count, noun, suffix = 's') => {
      return `${noun}${count !== 1 ? suffix : ''}`
    },

    /**
     * @function unixToFormat
     * @param {number} timestamp - Unix timestamp.
     * @param {string} formatString - String format as accepted by moment.js docs.
     * @example
     * // returns 1st April, 2020
     * unixToFormat(123980918219, 'Do MMMM, YYYY')
     */
    unixToFormat: (timestamp, formatString) => {
      return momentTz.unix(timestamp).format(formatString)
    },

    /**
     * @function momentFormat
     * @param {string} date - Date time string.
     * @param {string} formatString - String format as accepted by moment.js docs.
     * @example
     * // returns 2020
     * momentFormat('Thu Oct 01 2020 11:49:39 GMT+1000', 'YYYY')
     */
    momentFormat: (date, formatString) => {
      return momentTz(date).format(formatString)
    },

    /**
     * @function momentHumanDiff
     * @param {string} date - Date time string.
     * @example
     * // returns 20 hours
     * momentHumanDiff('Thu Oct 01 2020 11:49:39 GMT+1000')
     */
    momentHumanDiff: (date) => {
      return momentTz(date).fromNow(true)
    },

    /**
     * @function unixHumanDiff
     *
     * @param {number} date - Date timestamp.
     * @example
     * // returns in 5 days
     * unixHumanDiff('1650117600')
     */
    unixHumanDiff: (date) => {
      return momentTz.unix(date).fromNow()
    },

    /**
     * @function moneyFormat
     *
     * Format number to australian standard for money and currency.
     *
     * @param {number} value - the value that we want to format
     * @returns {string} - the formatted value
     */
    moneyFormat: (value) => {
      const formatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
      })

      return formatter.format(value)
    },

    /**
     * @function numberFormat
     *
     * Format number to decimal
     *
     * @param {number} value - the value that we want to format
     * @param {number} offset - optional, the number of decimal places
     * @returns {number} - the formatted value
     */
    numberFormat: (value, offset = 2) => {
      return value > 0 ? value.toFixed(offset) : 0
    },

    /**
     * @function truncateString
     * @param {string} text - The string to truncate
     * @param {number} maxLength - Max string length
     * @param {string} omission - The string to indicate text is omitted.
     * @returns {string} The truncated string with omission
     */
    truncateString: (text, maxLength, omission) => {
      return truncate(text, {
        length: maxLength,
        omission: omission ? omission : '...',
      })
    },

    /**
     * @function formatBytes
     *
     * Format bytes size into human readable format.
     *
     * @param {number} bytes - original bytes size.
     * @param {number} decimals - number of decimals it will return, by default it will have 1 decimals
     *
     * @returns {string} the formatted size.
     */
    formatBytes: (bytes, decimals = 1) => {
      if (isNil(bytes)) {
        return 'N/A'
      }

      if (bytes <= 0) {
        return '0 Bytes'
      }

      const minSize = 1024
      const decimalPlaces = decimals < 0 ? 0 : decimals
      const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const convertedBytes = Math.floor(Math.log(bytes) / Math.log(minSize))

      return `${parseFloat(
        (bytes / Math.pow(minSize, convertedBytes)).toFixed(decimalPlaces)
      )} ${units[convertedBytes]}`
    },

    /**
     * @function formatTime
     * @param {string} time - time string.
     * @param {string} currentFormat - String format as accepted by moment.js docs.
     * @param {string} newFormat - String format as accepted by moment.js docs.
     * @example formatTime('17:53, 'HH:mm', 'hh:mm a') // returns 05:53 pm
     */
    formatTime(time, currentFormat, newFormat) {
      return momentTz(time, currentFormat).format(newFormat)
    },

    /**
     * @function getLastUrlSegment
     * @param {string} value - url string.
     * @example getLastUrlSegment('https://www.domain.com.au/doc.pdf') // returns doc.pdf
     */
    getLastUrlSegment(value) {
      if (value) {
        return decodeURI(value).split('/').pop()
      }

      return ''
    },

    /**
     * @function getForeColor
     * @param {string} hexColour - hex colour string.
     * @example getForeColor('#000000') // returns #fff
     */
    getForeColor(hexColour) {
      // hex to rgb
      const R = parseInt(hexColour.substring(1, 3), 16),
        G = parseInt(hexColour.substring(3, 5), 16),
        B = parseInt(hexColour.substring(5, 7), 16)

      // get luminance value
      const luminance = Math.sqrt(R * R * 0.241 + G * G * 0.691 + B * B * 0.068)

      // 138 threshold, choose accordingly
      return luminance > 138 ? '#333' : '#fff'
    },

    /**
     * @function getDurationFormat
     * @param {int} duration - duration in seconds.
     * @example getDurationFormat(60) // returns '1 minute'
     */
    getDurationFormat(duration) {
      const hourDuration = momentTz.duration(duration, 'seconds').hours()
      const minuteDuration = momentTz.duration(duration, 'seconds').minutes()

      let durationFormat = null

      if (hourDuration > 0) {
        durationFormat = `${hourDuration} ${
          hourDuration > 1 ? 'hours' : 'hour'
        }`
      } else {
        if (minuteDuration > 0) {
          durationFormat = `${minuteDuration} ${
            minuteDuration > 1 ? 'minutes' : 'minute'
          }`
        }
      }

      return durationFormat
    },

    /**
     * @function cleanString
     *
     * This function is to get rid special characters to make it windows friendly.
     * List of illegal characters : / \ ! ? | " * < > { } #
     *
     * @param {string} text - string to clean.
     * @example cleanString('Hello/ raf & ibrar') // returns 'Hello raf and ibrar'
     */
    cleanString(text) {
      // eslint-disable-next-line
      const specialChars = /[:\/\\\!\?\|"*<>{}#]/g

      let newText = text.replace('&', 'and')

      return newText.replace(specialChars, '')
    },
  },
}
