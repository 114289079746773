<template>
  <div class="vd-display-inline-block vd-position-relative">
    <div
      v-click-outside="closeUploadFilesDropdown"
      class="vd-margin-left-small vms-project-files__list--item__icon vms-project-files__list--item__icon--medium vd-icon-three-dot"
      @click="toggleUploadFilesDropdown"
    ></div>

    <div
      v-if="showUploadFilesDropdown"
      class="vms-project-files__dropdown vd-position-absolute vd-background-white vd-box-shadow vd-background-white vd-border-radius-6"
    >
      <a
        :href="file.url"
        target="_blank"
        rel="noopener"
        class="vms-dropdown__list vms-project-files__dropdown-list vms-project-files__action--view vd-cursor-pointer vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-black vd-text-align-left"
      >
        View
      </a>
      <div
        class="vms-dropdown__list vms-project-files__dropdown-list vms-project-files__action--delete vd-cursor-pointer vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-black vd-text-align-left"
        @click="handleDelete"
      >
        Delete
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SweetAlert from '@plugins/sweet-alert'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },

  props: {
    file: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      showUploadFilesDropdown: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
    }),
  },

  methods: {
    ...mapActions({
      deleteBrandHubAttachment: 'project/attachment/deleteBrandHubAttachment',
    }),

    handleDelete() {
      SweetAlert.fire({
        title: 'Warning!',
        html: `<br>Are you sure you want to delete this file? <br><br> <strong>${this.file.name}</strong>`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteBrandHubAttachment({
            project_id: this.project.id,
            attachment_id: this.file.id,
          })
        }
      })
    },

    toggleUploadFilesDropdown() {
      this.showUploadFilesDropdown = !this.showUploadFilesDropdown
    },

    closeUploadFilesDropdown() {
      this.showUploadFilesDropdown = false
    },
  },
}
</script>
