<template>
  <table
    class="vms-project-files__content--wrapper-fixed vms-project-files__table table table-sm"
  >
    <thead>
      <tr>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          File name
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Uploaded at
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Uploaded by
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Size
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey"></th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(file, index) in filesData" :key="index">
        <td class="vd-border-light-grey">
          <a :href="file.url" target="_blank">
            <div
              :class="[
                'vms-project-files__icon vms-project-files__action--view vd-display-inline-block vd-margin-right-small',
                getIconClassForMimeType(file.mime_type),
              ]"
            ></div>
            <div
              class="vms-project-files__action--view vd-display-inline-block vd-black"
            >
              {{ file.name }}
            </div>
          </a>
        </td>
        <td class="vd-border-light-grey vd-dark-grey">
          {{ file.created_at | unixToFormat('DD MMM YYYY') }}
        </td>
        <td class="vd-border-light-grey">
          <div
            v-if="file.created_by.photo_url === null"
            class="vms-project-files__icon--user vd-display-inline-block vd-margin-right-small vd-icon-profile"
          ></div>
          <div
            v-else
            class="vms-project-files__icon--profile vd-display-inline-block vd-margin-right-small"
          >
            <img class="vd-border-radius-50" :src="file.created_by.photo_url" />
          </div>
          <div class="vd-display-inline-block">
            {{ file.created_by.full_name }}
          </div>
        </td>
        <td class="vd-border-light-grey vd-dark-grey">
          {{ file.size | formatBytes }}
        </td>
        <td class="vd-border-light-grey vd-text-align-right">
          <div class="vd-padding-right-medium vd-padding-left-medium">
            <slot name="uploaded-files-options" :file="file"></slot>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import IconsMixin from '@mixins/icons-mixin'

export default {
  mixins: [FiltersMixin, IconsMixin],

  props: {
    filesData: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },
}
</script>
